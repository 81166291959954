import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import env from "../../../../../environment.json";

export const useTargetDomain = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.hostname.includes("localhost")) {
      const params = new URL(window.location.toString()).searchParams;
      const targetDomain = params.get("targetDomain");

      if (targetDomain) {
        Auth.configure({
          endpoint: `https://${targetDomain}/auth`,
        });
      } else {
        Auth.configure({
          endpoint: "https://dev.autonomous365.ai/auth",
        });
      }
    } else {
      Auth.configure({
        endpoint: `https://${window.location.hostname}/auth`,
      });
    }
  }, []);

  const redirect = () => {
    if (window.location.hostname.includes("localhost")) {
      if (env?.stacks.webapp.domain.subdomains.length) {
        const domain = `${env.name}.${env.stacks.webapp.domain.apex}`;

        navigate(`/login?targetDomain=${domain}`, {
          replace: true,
        });
      } else {
        navigate("/login", { replace: true });
      }
    } else {
      navigate("/login", { replace: true });
    }
  };

  return { redirect };
};
